import { em, rem, rgba } from 'polished';
import { theme } from '@chakra-ui/core';

import customIcons from './icons';

// See https://chakra-ui.com/theme#spacing
export const spacing = {
  px: '1px',
  '0': '0',
  '1': '0.25rem', // 4px
  '2': '0.5rem', // 8px
  '3': '0.75rem', // 12px
  '4': '1rem', // 16px
  '5': '1.25rem', // 20px
  '6': '1.5rem', // 24px
  '8': '2rem', // 32px
  '10': '2.5rem', // 40px
  '12': '3rem', // 48px
  '16': '4rem', // 64px
  '20': '5rem', // 80px
  '24': '6rem', // 96px
  '32': '8rem', // 128px
  '40': '10rem', // 160px
  '48': '12rem', // 192px
  '56': '14rem', // 224px
  '64': '16rem', // 256px
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const breakpoints: any = [
  em('320px'),
  em('756px'),
  em('1152px'),
  em('1440px'),
];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

// Customised Chakra UI theme
// Default values: https://chakra-ui.com/theme
const customTheme = {
  ...theme,
  borders: theme.borders,
  breakpoints: breakpoints,
  colors: {
    ...theme.colors,
    // this is only here to stop chakra's checkbox variantColor warning
    highlights03Numbered: {
      500: '#0B194B',
    },
    lately: {
      background: '#FDFDFD',
      text: '#010822',
      core: '#FFDC62',
      core02: '#23305D',
      answers: '#FFEED8',
      secondary01: '#FF9F6A',
      secondary02: '#8EAE4B',
      highlights: '#F9F9FB',
      highlights02: '#FBE6CA',
      highlights03: '#0B194B',
      highlights04: '#F1CC6E',
      formsError: '#CD470D',
      forms02: '#597224',
      black20: '#D6D7D9',
      'black4%': rgba('#010822', 0.04),
      'black10%': rgba('#010822', 0.1),
      'core0210%': rgba('#23305D', 0.1),
      'core0205%': rgba('#23305D', 0.05),
      'black40%': rgba('#010822', 0.4),
      'highlights0305%': rgba('#0B194B', 0.05),
    },
  },
  fonts: {
    body: '"Nunito Sans", sans-serif',
    heading: '"Castledown", sans-serif',
    headingCampaign: '"haboro-condensed", serif',
    mono: 'monospace',
  },
  fontSizes: theme.fontSizes,
  fontWeights: theme.fontWeights,
  icons: {
    ...theme.icons,
    ...customIcons,
  },
  letterSpacings: theme.letterSpacings,
  lineHeights: theme.lineHeights,
  opacity: theme.opacity,
  radii: theme.radii,
  shadows: {
    ...theme.shadows,
    dropdown: '0 0 24px 5px rgba(36, 36, 36, 0.06)',
  },
  sizes: {
    ...theme.sizes,
    contentMax: rem('1440px'),
  },
  space: {
    ...theme.space,
    ...spacing,
  },
  zIndices: theme.zIndices,
};
export type CustomTheme = typeof customTheme;

export default customTheme;
