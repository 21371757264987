import React from 'react';

// Custom icons for Chakra UI theme
// Tip: Convert from SVG to JSX using https://transform.tools/
// https://chakra-ui.com/icon#adding-custom-icons
export default {
  copy: {
    path: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 10a1 1 0 011-1h9a1 1 0 011 1v9a1 1 0 01-1 1H5a1 1 0 01-1-1v-9zm2 1v7h7v-7H6z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 5a1 1 0 011-1h9a1 1 0 011 1v9a1 1 0 01-1 1h-1.5a1 1 0 110-2h.5V6h-7v.5a1 1 0 11-2 0V5z"
          fill="currentColor"
        />
      </>
    ),
    viewBox: '0 0 24 24',
  },
  conversation: {
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 82">
        <path
          d="M11.09 24.235c18.515-1.002 30.112-.944 48.271.008a3.901 3.901 0 0 1 3.686 3.525c.977 10.55.956 17.38.022 26.973-.189 1.941-1.794 3.43-3.742 3.505l-12.512.477a3.843 3.843 0 0 0-3.183 1.887c-2.246 3.852-3.207 6.105-5.898 8.908-1.367 1.423-3.644 1.387-4.989-.056-2.871-3.082-3.758-5.323-5.944-8.93-.663-1.094-1.845-1.757-3.122-1.806l-12.527-.478a3.907 3.907 0 0 1-3.753-3.561c-.806-9.419-.887-16.193.01-26.894a3.9 3.9 0 0 1 3.68-3.558Z"
          fill="#FFE073"
        />
        <path
          d="M25.437 10.658c16.827-.904 27.47-.852 43.974.008a3.892 3.892 0 0 1 3.681 3.522c.868 9.481.851 15.713.023 24.338-.186 1.941-1.792 3.432-3.74 3.507l-11.104.424a3.835 3.835 0 0 0-3.181 1.887c-1.96 3.375-2.86 5.413-5.168 7.873-1.35 1.439-3.636 1.404-4.966-.052-2.47-2.702-3.307-4.73-5.216-7.9-.66-1.095-1.842-1.757-3.12-1.806l-11.118-.424a3.905 3.905 0 0 1-3.752-3.563c-.715-8.466-.784-14.647.011-24.259a3.89 3.89 0 0 1 3.676-3.555Z"
          stroke="#FFF9EB"
          strokeWidth="2"
        />
      </svg>
    ),
    viewBox: '0 0 82 82',
  },
  conversationDark: {
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path
          d="M53.986 16.493c-15.334-.84-24.79-.79-39.825.005a2.748 2.748 0 0 0-2.592 2.478c-.838 8.884-.82 14.498-.017 22.57a2.745 2.745 0 0 0 2.632 2.464l10.726.41c.922.034 1.77.527 2.238 1.323 1.973 3.363 2.723 5.233 5.15 7.677a2.463 2.463 0 0 0 3.528-.041c2.584-2.692 3.265-4.553 5.185-7.693a2.682 2.682 0 0 1 2.195-1.265l10.737-.41a2.75 2.75 0 0 0 2.638-2.503c.692-7.93.764-13.496-.007-22.515a2.746 2.746 0 0 0-2.588-2.5Z"
          fill="#FFDF8F"
        />
        <path
          d="M42.362 5.494C28.4 4.734 19.714 4.78 6.022 5.5A2.744 2.744 0 0 0 3.43 7.978c-.749 8.015-.732 13.15-.017 20.435a2.741 2.741 0 0 0 2.631 2.463l9.585.366c.923.036 1.77.528 2.237 1.325 1.74 2.975 2.447 4.677 4.556 6.84a2.435 2.435 0 0 0 3.515-.038c2.25-2.381 2.898-4.075 4.59-6.858a2.677 2.677 0 0 1 2.195-1.267l9.596-.366a2.747 2.747 0 0 0 2.637-2.502c.618-7.156.681-12.248-.007-20.381a2.743 2.743 0 0 0-2.587-2.5Z"
          stroke="#23305D"
          strokeWidth="2"
        />
      </svg>
    ),
    viewBox: '0 0 60 60',
  },
  dots: {
    path: (
      <path
        d="M3.951 10.975a1.975 1.975 0 11-3.95 0 1.975 1.975 0 013.95 0zM11.358 10.975a1.975 1.975 0 11-3.95 0 1.975 1.975 0 013.95 0zM21.476 10.975a1.975 1.975 0 11-3.95 0 1.975 1.975 0 013.95 0z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 22 22',
  },
  save: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3h10c1.1 0 2 .9 2 2v16l-7-3-7 3V5c0-1.1.9-2 2-2zm5 12.82L17 18V6c0-.55-.45-1-1-1H8c-.55 0-1 .45-1 1v12l5-2.18z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  map: {
    path: (
      <path
        d="M14.955 6.689L9.63 4m5.325 2.689L20.585 4v12.674l-5.63 2.69m0-12.675v12.674m0 0L9.63 16.674m0 0L4 19.364V6.688L9.63 4m0 12.674V4"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        fill="none"
      />
    ),
    viewBox: '0 0 24 24',
  },
  location: {
    path: (
      <path
        d="M16 3.346c-5.148 0-9.31 4.209-9.31 9.413 0 5.081 4.821 11.903 7.525 15.33a2.253 2.253 0 003.57 0c2.704-3.427 7.525-10.249 7.525-15.33 0-5.204-4.163-9.413-9.31-9.413zm0 12.776c-1.836 0-3.325-1.507-3.325-3.363 0-1.855 1.49-3.362 3.325-3.362 1.835 0 3.325 1.507 3.325 3.362 0 1.856-1.49 3.363-3.325 3.363z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 32 32',
  },
  locationCircle: {
    path: (
      <>
        <circle cx={24.5596} cy={24.5596} r={24.5596} fill="#fff" />
        <path
          d="M24.56 13.43c-4.456 0-8.06 3.644-8.06 8.149 0 5.298 6.058 12.016 7.67 13.71a.532.532 0 00.779 0c1.61-1.694 7.669-8.412 7.669-13.71 0-4.505-3.604-8.149-8.059-8.149zm0 11.059c-1.59 0-2.879-1.304-2.879-2.91 0-1.607 1.29-2.91 2.878-2.91 1.589 0 2.878 1.303 2.878 2.91 0 1.606-1.29 2.91-2.878 2.91z"
          fill="#0B194B"
        />
      </>
    ),
    viewBox: '0 0 50 50',
  },
  print: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.421 3.132h10c.55 0 1 .45 1 1v2c0 .55-.45 1-1 1h-10c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1zm-2 5h14c1.66 0 3 1.34 3 3v4c0 1.1-.9 2-2 2h-2v2c0 1.1-.9 2-2 2h-8c-1.1 0-2-.9-2-2v-2h-2c-1.1 0-2-.9-2-2v-4c0-1.66 1.34-3 3-3zm4 11h6c.55 0 1-.45 1-1v-4h-8v4c0 .55.45 1 1 1zm10-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 25 25',
  },
  share: {
    path: (
      <path
        d="M16.421 11.131c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5v1.5a1 1 0 001 1h12a1 1 0 001-1v-1.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.335 0-.466.44-.23.678.75.752 1.23 1.683 1.23 2.822v1.5a1 1 0 001 1h4a1 1 0 001-1v-1.5c0-2.33-4.67-3.5-7-3.5z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 25 25',
  },
  arrowForward: {
    path: (
      <path
        d="M5.209 12.995h11.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42a.996.996 0 001.41 0l6.59-6.59a.996.996 0 000-1.41l-6.59-6.59a.996.996 0 10-1.41 1.41l4.88 4.88H5.209c-.55 0-1 .45-1 1s.45 1 1 1z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  arrowBack: {
    path: (
      <path
        d="M18.791 11.005H7.621l4.88-4.88c.39-.39.39-1.03 0-1.42a.996.996 0 00-1.41 0l-6.59 6.59a.996.996 0 000 1.41l6.59 6.59a.996.996 0 101.41-1.41l-4.88-4.88h11.17c.55 0 1-.45 1-1s-.45-1-1-1z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  accessibility: {
    path: (
      <path
        d="M21.168 7.912a1.035 1.035 0 0 0-1.235-.746c-2.375.528-5.032.759-7.512.759-2.48 0-5.136-.23-7.512-.759a1.035 1.035 0 0 0-1.235.746l-.015.06a.97.97 0 0 0 .716 1.19c1.324.302 2.746.526 4.132.675.516.055.914.487.914 1.006v11.082a1 1 0 1 0 2 0v-4a1 1 0 0 1 2 0v4a1 1 0 0 0 2 0V10.843c0-.519.398-.95.915-1.006a36.24 36.24 0 0 0 4.132-.676.97.97 0 0 0 .715-1.19l-.015-.06zm-8.747-.987c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  accordionChevron: {
    path: (
      <path
        d="M4.931 1L.341 5.59A.996.996 0 101.751 7l3.89-3.88L9.521 7a.996.996 0 101.41-1.41L6.341 1a.996.996 0 00-1.41 0z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 12 8',
  },
  add: {
    path: (
      <path
        d="M18.421 13.338h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5h-5c-.55 0-1-.45-1-1s.45-1 1-1h5v-5c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  alert: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.948 19.896a1 1 0 0 0 .865 1.5h18.195a1 1 0 0 0 .866-1.5L13.776 4.182a1 1 0 0 0-1.73 0L2.947 19.896zm10.947-2.438a.985.985 0 1 1-1.969 0 .985.985 0 0 1 1.97 0zm-.984-1.97c.543 0 .984-.44.984-.984V11.55a.985.985 0 0 0-1.969 0v2.954c0 .543.44.984.985.984z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  areasServiced: {
    path: (
      <path
        d="M15.221 6.991a1 1 0 0 0-1.6 0l-2.5 3.334a1 1 0 0 0 0 1.2l1.8 2.4a1 1 0 0 1-1.6 1.201l-3.1-4.135a1 1 0 0 0-1.6 0l-4 5.334a1 1 0 0 0 .8 1.6h18a1 1 0 0 0 .8-1.6l-7-9.334z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  bookmark: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42114 3.13184H17.4211C18.5211 3.13184 19.4211 4.03184 19.4211 5.13184V21.1318L12.4211 18.1318L5.42114 21.1318V5.13184C5.42114 4.03184 6.32114 3.13184 7.42114 3.13184ZM12.4211 15.9518L17.4211 18.1318V6.13184C17.4211 5.58184 16.9711 5.13184 16.4211 5.13184H8.42114C7.87114 5.13184 7.42114 5.58184 7.42114 6.13184V18.1318L12.4211 15.9518Z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 25 25',
  },
  bullet: {
    path: (
      <path
        d="M10.596 10.749c-2.218 1.3-1.91 2.675-1.072 4.105a3 3 0 005.177-3.033c-.837-1.43-1.886-2.372-4.105-1.072z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 25 25',
  },
  check: {
    path: (
      <path
        d="M1.5 7.786L5.167 12.5l7.333-11"
        fill="none"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    ),
    viewBox: '0 0 14 14',
  },
  close: {
    path: (
      <path
        d="M13.721 1.048a.996.996 0 00-1.41 0l-4.89 4.88-4.89-4.89a.996.996 0 10-1.41 1.41l4.89 4.89-4.89 4.89a.996.996 0 101.41 1.41l4.89-4.89 4.89 4.89a.996.996 0 101.41-1.41l-4.89-4.89 4.89-4.89c.38-.38.38-1.02 0-1.4z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 15 15',
  },
  community: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 20.25a9.25 9.25 0 100-18.5 9.25 9.25 0 000 18.5zM11 22c6.075 0 11-4.925 11-11S17.075 0 11 0 0 4.925 0 11s4.925 11 11 11zm0-6.75a4.25 4.25 0 100-8.5 4.25 4.25 0 000 8.5zM11 17a6 6 0 100-12 6 6 0 000 12zm1-6a1 1 0 11-2 0 1 1 0 012 0z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 22 22',
  },
  conversationStarter: {
    path: (
      <>
        <path
          d="M15.984 0C4.685 0 0 7.25 0 16.193 0 25.136 8.441 32 17.269 32c8.828 0 13.975-5.54 14.699-15.807C32.549 7.944 25.196 0 15.984 0z"
          fill="#FFDC62"
        />
        <path
          d="M7.824 12.028c5.224-.285 8.459-.268 13.581.002a.98.98 0 01.926.885c.282 3.013.276 4.93.005 7.668a.98.98 0 01-.94.88l-3.62.138a.965.965 0 00-.799.472c-.661 1.13-.922 1.767-1.73 2.588a.875.875 0 01-1.257-.014c-.861-.904-1.099-1.538-1.743-2.593a.957.957 0 00-.783-.453l-3.625-.138a.981.981 0 01-.942-.894c-.233-2.689-.257-4.59.003-7.647a.98.98 0 01.924-.894z"
          fill="#FDFDFD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.835 8.758c4.735-.257 7.688-.242 12.334.002a.48.48 0 01.453.432c.25 2.685.244 4.405.006 6.841a.478.478 0 01-.46.429l-3.23.123a1.465 1.465 0 00-1.211.72c-.135.233-.25.437-.355.623-.364.648-.598 1.064-1.1 1.584a.364.364 0 01-.528-.009c-.51-.545-.742-.953-1.064-1.518-.119-.21-.25-.44-.412-.706a1.455 1.455 0 00-1.192-.693l-3.233-.124a.48.48 0 01-.463-.436c-.205-2.397-.226-4.107.003-6.832a.479.479 0 01.453-.436zm12.387-.996c-4.679-.246-7.668-.261-12.44-.002a1.479 1.479 0 00-1.395 1.35c-.235 2.784-.213 4.55-.003 7.001a1.48 1.48 0 001.42 1.35l3.234.124c.161.006.3.089.375.212.132.218.248.422.36.62.34.598.644 1.132 1.241 1.77.53.566 1.432.585 1.978.02.598-.62.903-1.164 1.28-1.838.1-.176.203-.361.319-.56a.465.465 0 01.386-.225l3.229-.123a1.478 1.478 0 001.418-1.33c.244-2.503.25-4.286-.006-7.032a1.48 1.48 0 00-1.396-1.337z"
          fill="#010822"
        />
      </>
    ),
    viewBox: '0 0 32 32',
  },
  email: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.028 10.718A9.991 9.991 0 0110.718.028c5.29.36 9.28 4.98 9.28 10.29v1.09c0 1.44-.82 2.81-2.16 3.34-1.63.65-3.41.01-4.3-1.21-1.15 1.17-2.9 1.76-4.74 1.33a4.996 4.996 0 01-3.72-3.94 5.008 5.008 0 015.66-5.87c2.48.35 4.26 2.61 4.26 5.12v1.25c0 .79.71 1.57 1.5 1.57s1.5-.78 1.5-1.57v-1.1c0-3.73-2.42-7.15-6.04-8.07-5.8-1.5-11.2 3.91-9.71 9.71.93 3.61 4.35 6.03 8.08 6.03h3.67c.55 0 1 .45 1 1s-.45 1-1 1h-3.69c-5.3 0-9.92-3.99-10.28-9.28zm6.97-.72c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 20 20',
  },
  externalLink: {
    path: (
      <>
        <path
          d="M16.013 0a1 1 0 010 2H9.208a1 1 0 110-2h6.805z"
          fill="currentColor"
        />
        <path
          d="M17.013 7.787a1 1 0 11-2 0V1a1 1 0 112 0v6.787zM17.027 15a1 1 0 01-2 0v-1.858a1 1 0 112 0V15zM4.973 1a1 1 0 01-1 1h-2a1 1 0 010-2h2a1 1 0 011 1z"
          fill="currentColor"
        />
        <path
          d="M16.973 15a1 1 0 01-1 1h-14a1 1 0 110-2h14a1 1 0 011 1z"
          fill="currentColor"
        />
        <path
          d="M2.973 15a1 1 0 11-2 0V1a1 1 0 112 0v14zM13.906 1.675a1 1 0 011.414 1.414L8.915 9.494A1 1 0 117.501 8.08l6.405-6.405z"
          fill="currentColor"
        />
      </>
    ),
    viewBox: '0 0 18 16',
  },
  facebook: {
    path: (
      <path
        d="M23.998 12c0-6.628-5.372-12-11.999-12C5.372 0 0 5.372 0 12c0 5.988 4.388 10.952 10.124 11.852v-8.384H7.078v-3.469h3.046V9.356c0-3.008 1.792-4.669 4.532-4.669 1.313 0 2.686.234 2.686.234v2.953H15.83c-1.49 0-1.955.925-1.955 1.874V12h3.328l-.532 3.469h-2.796v8.384c5.736-.9 10.124-5.864 10.124-11.853z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  filter: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 8c0 .55-.45 1-1 1s-1-.45-1-1V4c0-.55.45-1 1-1s1 .45 1 1v1h3c.55 0 1 .45 1 1s-.45 1-1 1h-3v1zm-13-1c-.55 0-1-.45-1-1s.45-1 1-1h9v2h-9zm0 12c-.55 0-1-.45-1-1s.45-1 1-1h5v2h-5zm9 1v-1h7c.55 0 1-.45 1-1s-.45-1-1-1h-7v-1c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1s1-.45 1-1zm-6-9v-1c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1s-1-.45-1-1v-1h-3c-.55 0-1-.45-1-1s.45-1 1-1h3zm14 1c0-.55-.45-1-1-1h-9v2h9c.55 0 1-.45 1-1z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 25',
  },
  glossary: {
    path: (
      <path
        d="M4.07 14.12l1.1-2.72m6.75 2.72L10.8 11.4m-5.63 0l2.81-6.9 2.82 6.9m-5.63 0h5.63M14.66 13.23h5.97l-5.8 6.27h6.1"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    ),
    viewBox: '0 0 25 24',
  },
  instagram: {
    path: (
      <path
        d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913a5.885 5.885 0 001.384 2.126A5.868 5.868 0 004.14 23.37c.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558a5.898 5.898 0 002.126-1.384 5.86 5.86 0 001.384-2.126c.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913a5.89 5.89 0 00-1.384-2.126A5.847 5.847 0 0019.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227a3.81 3.81 0 01-.899 1.382 3.744 3.744 0 01-1.38.896c-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421a3.716 3.716 0 01-1.379-.899 3.644 3.644 0 01-.9-1.38c-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678a6.162 6.162 0 100 12.324 6.162 6.162 0 100-12.324zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405a1.441 1.441 0 01-2.88 0 1.44 1.44 0 012.88 0z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  letter: {
    path: (
      <>
        <g clipPath="url(#a)">
          <path
            d="M12.938 24.122c6.66 0 12.06-5.4 12.06-12.061S19.599 0 12.939 0C6.277 0 .877 5.4.877 12.06c0 6.662 5.4 12.062 12.06 12.062Z"
            fill="currentColor"
          />
          <path
            d="m12.939 13.644 7.199-6.162a.773.773 0 0 0-.415-.132H6.155a.773.773 0 0 0-.415.132l7.199 6.162Z"
            fill="#fff"
          />
          <path
            d="M13.43 14.21a.745.745 0 0 1-.98 0L5.402 8.197v7.82c0 .415.34.754.754.754h13.569c.414 0 .753-.34.753-.754v-7.84L13.43 14.21Z"
            fill="#fff"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path
              fill="#fff"
              transform="translate(.879)"
              d="M0 0h24.122v24.122H0z"
            />
          </clipPath>
        </defs>
      </>
    ),
    viewBox: '0 0 25 25',
  },
  linkedIn: {
    path: (
      <>
        <path
          d="M12.313 24.122c6.66 0 12.06-5.4 12.06-12.061S18.974 0 12.314 0C5.652 0 .252 5.4.252 12.06c0 6.662 5.4 12.062 12.06 12.062Z"
          fill="currentColor"
        />
        <path
          d="M8.543 8.292H5.527v10.553h3.016V8.292ZM15.704 8.292c-2.104 0-2.466.767-2.638 1.507V8.292h-3.015v10.553h3.015v-6.03c0-.978.53-1.508 1.508-1.508.954 0 1.507.519 1.507 1.508v6.03h3.015v-5.277c0-3.015-.392-5.276-3.393-5.276ZM7.035 7.538a1.508 1.508 0 1 0 0-3.015 1.508 1.508 0 0 0 0 3.015Z"
          fill="#F7F7FB"
        />
      </>
    ),
    viewBox: '0 0 25 25',
  },
  menuHamburger: {
    path: (
      <path
        d="M0 1a1 1 0 011-1h14a1 1 0 110 2H1a1 1 0 01-1-1zM0 6a1 1 0 011-1h14a1 1 0 110 2H1a1 1 0 01-1-1zM0 11a1 1 0 011-1h14a1 1 0 110 2H1a1 1 0 01-1-1z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 16 12',
  },
  search: {
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2 7a5 5 0 1 1-7.06 7.06A5 5 0 0 1 14.2 7zm2.02 7.8a7 7 0 1 0-1.44 1.4l4.17 4.16a1 1 0 0 0 1.41-1.41l-4.14-4.15z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  twitter: {
    path: (
      <path
        d="M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
  twitterSolid: {
    path: (
      <>
        <g clipPath="url(#a)">
          <path
            d="M24.749 12.06c0 6.661-5.4 12.062-12.061 12.062-6.66 0-12.061-5.4-12.061-12.061C.627 5.4 6.027 0 12.687 0 19.349 0 24.75 5.4 24.75 12.06Zm-4.022-4.079a6.18 6.18 0 0 1-1.777.487 3.105 3.105 0 0 0 1.361-1.712 6.217 6.217 0 0 1-1.965.75 3.094 3.094 0 0 0-5.27 2.821A8.782 8.782 0 0 1 6.7 7.097a3.092 3.092 0 0 0 .958 4.129 3.073 3.073 0 0 1-1.402-.387v.039a3.094 3.094 0 0 0 2.482 3.032 3.102 3.102 0 0 1-1.397.053 3.096 3.096 0 0 0 2.89 2.148 6.189 6.189 0 0 1-3.841 1.325 6.19 6.19 0 0 1-.739-.042 8.742 8.742 0 0 0 4.741 1.39c5.69 0 8.8-4.712 8.8-8.8a8.74 8.74 0 0 0-.009-.4 6.235 6.235 0 0 0 1.543-1.602Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path
              fill="#fff"
              transform="translate(.627)"
              d="M0 0h24.122v24.122H0z"
            />
          </clipPath>
        </defs>
      </>
    ),
    viewBox: '0 0 25 25',
  },
  youtube: {
    path: (
      <path
        d="M23.495 6.205a3.007 3.007 0 00-2.088-2.088c-1.87-.501-9.396-.501-9.396-.501s-7.507-.01-9.396.501A3.007 3.007 0 00.527 6.205a31.247 31.247 0 00-.522 5.805 31.247 31.247 0 00.522 5.783 3.007 3.007 0 002.088 2.088c1.868.502 9.396.502 9.396.502s7.506 0 9.396-.502a3.007 3.007 0 002.088-2.088 31.247 31.247 0 00.5-5.783 31.247 31.247 0 00-.5-5.805zM9.609 15.601V8.408l6.264 3.602z"
        fill="currentColor"
      />
    ),
    viewBox: '0 0 24 24',
  },
};
