import React, { useCallback, useContext, useMemo, useState } from 'react';

type SearchModalContextProps = {
  isOpen: boolean;
  mobileDefaultPage: 1 | 2;
  updateExistingSearch: boolean;
  onOpen: () => void;
  onOpenPageTwo: (openingFromSearchResults: boolean) => void;
  onClose: () => void;
};

const SearchModalContext = React.createContext<
  SearchModalContextProps | undefined
>(undefined);

export const SearchModalProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mobileDefaultPage, setMobileDefaultPage] = useState<1 | 2>(1);
  const [updateExistingSearch, setUpdateExistingSearch] = useState(false);

  const onOpen = useCallback(() => {
    setIsOpen(true);
    setMobileDefaultPage(1);
    setUpdateExistingSearch(false);
  }, []);

  const onOpenPageTwo = useCallback((openingFromSearchResults: boolean) => {
    setIsOpen(true);
    setMobileDefaultPage(2);
    // If modal is opened from Search Results form, it should update the existing search
    setUpdateExistingSearch(openingFromSearchResults);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  // Memoise the context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      isOpen,
      mobileDefaultPage,
      updateExistingSearch,
      onOpen,
      onOpenPageTwo,
      onClose,
    }),
    [
      isOpen,
      mobileDefaultPage,
      updateExistingSearch,
      onOpen,
      onOpenPageTwo,
      onClose,
    ]
  );

  return (
    <SearchModalContext.Provider value={value}>
      {children}
    </SearchModalContext.Provider>
  );
};

export const useSearchModal = () => {
  const context = useContext(SearchModalContext);
  if (context === undefined) {
    throw new Error('useSearchModal must be used within a SearchModalProvider');
  }
  return context;
};
