import React, {
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  SavedContentState,
  SavedContentType,
  createItem,
  getSavedContent,
  isSameItem,
  removeItem,
  saveItem,
} from '@/utils/saved-content';
import type { SavedContent } from '@/utils/saved-content';

type SavedContentContextProps = {
  savedItems: SavedContent[];
  pendingItems: SavedContent[];
  loadedItems: boolean;
  getItemState: (
    id: string,
    type: SavedContentType
  ) => [state: SavedContentState, toggle: () => void];
};

export const SavedContentContext = React.createContext<
  SavedContentContextProps | undefined
>(undefined);

export const SavedContentProvider: React.FC = ({ children }) => {
  const [savedItems, setSavedItems] = useState<SavedContent[]>([]);
  const [pendingItems, setPendingItems] = useState<SavedContent[]>([]);
  const [loadedItems, setLoadedItems] = useState(false);

  // On page load retrieve the list of saved items from storage
  useEffect(() => {
    getSavedContent().then((_savedItems) => {
      setSavedItems(_savedItems);
      setLoadedItems(true);
    });
  }, []);

  // Toggle the saved state of a piece of content
  const onToggleItem = useCallback(
    (id: string, type: SavedContentType, state: SavedContentState) => {
      // If the content is already pending, don't do anything
      if (state === SavedContentState.Pending) return;

      // Create the item and add it to the pending list
      const item = createItem(id, type);
      setPendingItems((_pendingItems) => [..._pendingItems, item]);

      // If the item hasn't been saved then save it, otherwise remove it
      const toggleItem =
        state === SavedContentState.None ? saveItem : removeItem;
      toggleItem(item)
        .then((_savedItems) => {
          // Remove the item from the pending list
          setPendingItems((_pendingItems) =>
            _pendingItems.filter((i) => !isSameItem(i, item))
          );
          // Update our local copy of the saved list
          setSavedItems(_savedItems);
        })
        .catch((err) => {
          // TODO: What should we do?
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    []
  );

  // Get the saved state of a piece of content
  const getItemState = useCallback(
    (id: string, type: SavedContentType): [SavedContentState, () => void] => {
      const item = createItem(id, type);
      const isPending =
        pendingItems.findIndex((i) => isSameItem(i, item)) !== -1;
      const isSaved = savedItems.findIndex((i) => isSameItem(i, item)) !== -1;

      let state: SavedContentState;
      if (isPending) {
        state = SavedContentState.Pending;
      } else if (isSaved) {
        state = SavedContentState.Saved;
      } else {
        state = SavedContentState.None;
      }

      return [state, () => onToggleItem(id, type, state)];
    },
    [pendingItems, savedItems]
  );

  // Memoise the context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      savedItems,
      pendingItems,
      loadedItems,
      getItemState,
    }),
    [savedItems, pendingItems, loadedItems, getItemState]
  );

  return (
    <SavedContentContext.Provider value={value}>
      {children}
    </SavedContentContext.Provider>
  );
};

export const useSavedContent = () => {
  const context = useContext(SavedContentContext);
  if (context === undefined) {
    throw new Error(
      'useSavedContent must be used within a SavedContentProvider'
    );
  }
  return context;
};
