import React from 'react';
import { Global, css } from '@emotion/core';
import { CSSReset, ThemeProvider } from '@chakra-ui/core';

import theme from '@/theme/chakra';

import { SavedContentProvider } from './SavedContentContext';
import { SearchModalProvider } from './SearchModalContext';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <SearchModalProvider>
        <SavedContentProvider>
          <CSSReset />
          <Global
            styles={css`
              @font-face {
                font-family: 'Castledown';
                src: url('/fonts/castledown-bold-italic.otf');
                font-weight: bold;
                font-style: italic;
                font-display: swap;
              }
              @font-face {
                font-family: 'Castledown';
                src: url('/fonts/castledown-bold.otf');
                font-weight: bold;
                font-style: normal;
                font-display: swap;
              }
              html {
                height: 100%;
              }
              body {
                overflow-x: hidden;
              }
              html,
              body,
              #root,
              #__next {
                color: ${theme.colors.lately.text};
                font-family: ${theme.fonts.body};
                height: 100%;
              }
              .visually-hidden {
                clip: rect(0 0 0 0);
                clip-path: inset(50%);
                height: 1px;
                overflow: hidden;
                position: absolute;
                white-space: nowrap;
                width: 1px;
              }
            `}
          />
          {children}
        </SavedContentProvider>
      </SearchModalProvider>
    </ThemeProvider>
  );
};

export default AppProvider;
