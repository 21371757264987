import localforage from 'localforage';

const key = 'saved-content';
const store = localforage.createInstance({ name: 'savedContent' });

export enum SavedContentType {
  Service = 'service',
  Faq = 'faq',
}

export enum SavedContentState {
  None = 'none',
  Pending = 'pending',
  Saved = 'saved',
}

export type SavedContent = {
  id: string;
  type: SavedContentType;
  saved: number;
};

// Get the saved list from storage (return an empty array if there's nothing saved)
export const getSavedContent = async (): Promise<SavedContent[]> => {
  return store.getItem<SavedContent[]>(key).then((arr) => {
    return arr ?? [];
  });
};

export const createItem = (id: string, type: SavedContentType) => {
  return { id: id, type: type, saved: Date.now() };
};

export const isSameItem = (a: SavedContent, b: SavedContent) => {
  return a.id === b.id && a.type === b.type;
};

export const saveItem = async (item: SavedContent) => {
  // Get the saved list from storage
  return getSavedContent().then((arr) => {
    // Add the item and update it
    const newArr = [...arr, item];
    return store.setItem(key, newArr);
  });
};

export const removeItem = async (item: SavedContent) => {
  // Get the saved list from storage
  return getSavedContent().then((arr) => {
    // Remove the item and update it
    const newArr = arr.filter((i) => !isSameItem(i, item));
    return store.setItem(key, newArr);
  });
};
